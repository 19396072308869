import ApplicationController from 'modules/application_controller';
import CachedTurboContent from 'modules/cached_turbo_content';
import remove from 'plugins/element/remove';
import checkDelayedResult from 'plugins/utilities/check_delayed_result';

export default class extends ApplicationController {
  static get targets() {
    return ['content', 'skeleton', 'scrollArea', 'pagination', 'nextLink', 'spinner'];
  }

  initialize() {
    this.observer = null;
    this.observing = false;

    this.props = {
      loadAllEnabled: false,
      firstLoad: true,
    };
  }

  connect() {
    if (this.hasNextLinkTarget) {
      this.nextLinkTarget.classList.toggle('disabled', this.isTurboPreview);
    }

    if (this.isTurboPreview) {
      return;
    }

    this.setSkeletonChildrenName();

    if (this.manualLoad) {
      return;
    }

    this.createObserver();
    this.observe();
  }

  disconnect() {
    this.unobserve();
    this.destroyObserver();
  }

  createObserver() {
    if (this.observer) {
      return;
    }

    this.observer = new IntersectionObserver((entries) => this.loadMoreByScroll(entries), {
      threshold: [0, 1.0],
    });
  }

  destroyObserver() {
    if (!this.observer) {
      return;
    }

    this.observer = null;
  }

  observe() {
    if (!this.observer || !this.hasScrollAreaTarget || this.observing) {
      return;
    }

    this.observing = true;
    this.observer.observe(this.scrollAreaTarget);
  }

  unobserve() {
    if (!this.observer || !this.hasScrollAreaTarget || !this.observing) {
      return;
    }

    this.observer.unobserve(this.scrollAreaTarget);
    this.observing = false;
  }

  storeOrRenderCachedTurboContent() {
    CachedTurboContent.storeOrRender({
      element: this.element,
      key: `infiniteScrollCache#${this.feedName}`,
      contentCallback: () => this.contentTarget,
      beforeCacheCallback: (content) => {
        this.scrollAreaTarget.innerHTML = '';

        if (this.hasSkeletonTarget) {
          const skeleton = document.createElement('div');
          skeleton.innerHTML = this.skeletonTarget.innerHTML;
          const skeletonChildrenCount = skeleton.children.length;

          while (content.children.item(skeletonChildrenCount)) {
            remove(content.children.item(skeletonChildrenCount));
          }
        }

        return content;
      },
      renderCallback: (content) => {
        content.className = content.className.replace(/[ ]*tw-mt-[0-9]+[ ]*/, '');
        content.className += ' tw-mt-0';

        this.scrollAreaTarget.insertAdjacentElement('afterbegin', content);
      },
    });
  }

  loadMoreByScroll(entries) {
    entries.forEach((entry) => {
      if (this.loadAllEnabled) {
        return;
      }

      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  loadMoreByClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.loadAllEnabled) {
      return;
    }

    if (this.nextLinkTarget.classList.contains('disabled')) {
      return;
    }

    this.loadMore();
  }

  updatePreviewChildrenCountBySkeleton() {
    if (!this.hasSkeletonTarget) {
      return;
    }

    const skeleton = document.createElement('div');
    skeleton.innerHTML = this.skeletonTarget.innerHTML;
    const skeletonChildrenCount = skeleton.children.length;

    while (this.contentTarget.children.item(skeletonChildrenCount)) {
      remove(this.contentTarget.children.item(skeletonChildrenCount));
    }
  }

  setSkeletonChildrenName() {
    if (!this.hasSkeletonTarget) {
      return;
    }

    const skeleton = document.createElement('div');
    skeleton.innerHTML = this.skeletonTarget.innerHTML;

    for (const children of skeleton.children) {
      if (children.nodeName === 'TABLE') {
        for (const nestedChildren of children.children) {
          nestedChildren.setAttribute('name', this.skeletonItemName);
        }
      } else {
        children.setAttribute('name', this.skeletonItemName);
      }
    }

    this.skeletonTarget.innerHTML = skeleton.innerHTML;
  }

  setSkeleton() {
    if (!this.hasSkeletonTarget) {
      return;
    }

    if (this.scrollAreaTarget.innerHTML !== '') {
      return;
    }

    if (this.useScrollAreaForSkeleton) {
      this.scrollAreaTarget.innerHTML = this.skeletonTarget.innerHTML;

      if (!this.firstLoad) {
        this.scrollAreaTarget.firstElementChild.classList.add(this.skeletonTarget.dataset.classAfterFirstLoad);
      }

      return;
    }

    this.contentTarget.insertAdjacentHTML('beforeend', this.skeletonTarget.innerHTML);
  }

  removeSkeleton() {
    this.scrollAreaTarget.innerHTML = '';

    if (!this.hasContentTarget) {
      return;
    }

    while (this.contentTarget.children.namedItem(this.skeletonItemName)) {
      remove(this.contentTarget.children.namedItem(this.skeletonItemName));
    }
  }

  loadMore() {
    this.unobserve();

    if (!this.hasNextLinkTarget) {
      return;
    }

    if (this.nextLinkTarget.classList.contains('disabled')) {
      if (this.loadAllEnabled) {
        setTimeout(() => {
          this.loadMore();
        }, 250);
      }

      return;
    }

    this.setSkeleton();

    if (this.hasScrollAreaTarget && !this.useScrollAreaForSkeleton && this.scrollAreaTarget.innerHTML === '') {
      // Hide the scroll area so it does not take space in the grids between content and skeleton
      // but only if it does not contain the skeleton or is not supposed to be outside the content
      this.scrollAreaTarget.hidden = true;
    }

    this.nextLinkTarget.classList.add('disabled');

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.hidden = false;
    }

    const href = this.nextLinkTarget.href;

    const handleHTML = (html) => {
      window.Turbo.renderStreamMessage(html);

      requestAnimationFrame(() => {
        this.removeSkeleton();

        if (this.hasScrollAreaTarget && this.hasContentTarget && !this.useScrollAreaForSkeleton) {
          this.contentTarget.insertAdjacentElement('beforeend', this.scrollAreaTarget);
        }

        this.scrollAreaTarget.hidden = false;
        this.firstLoad = false;

        if (this.loadAllEnabled) {
          this.loadMore();
        } else {
          this.observe();
        }
      });
    };

    fetch(href, {
      credentials: 'include',
      headers: {
        Accept: this.acceptType,
      },
    })
      .then((response) => {
        if (this.isDelayedResult) {
          response
            .json()
            .then((result) =>
              checkDelayedResult(result.delayed_result_id, ({ content_html: html }) => handleHTML(html), 500),
            );

          return null;
        }

        return response.text();
      })
      .then((html) => {
        if (html) {
          handleHTML(html);
        }
      });
  }

  loadAll({ detail }) {
    if (this.feedName !== detail.feed) {
      return;
    }

    this.loadAllEnabled = true;

    this.loadMore();
  }

  get loadAllEnabled() {
    return this.props.loadAllEnabled;
  }

  set loadAllEnabled(value) {
    this.props.loadAllEnabled = value;
  }

  get firstLoad() {
    return this.props.firstLoad;
  }

  set firstLoad(value) {
    this.props.firstLoad = value;
  }

  get feedName() {
    return this.element.dataset.feedName;
  }

  get isDelayedResult() {
    return this.element.dataset.delayedResult === 'true';
  }

  get acceptType() {
    if (this.isDelayedResult) {
      return 'text/javascript';
    }

    return 'text/vnd.turbo-stream.html';
  }

  get manualLoad() {
    return this.element.dataset.manualLoad === 'true';
  }

  get useScrollAreaForSkeleton() {
    return this.hasSkeletonTarget && this.skeletonTarget.dataset.useScrollAreaForSkeleton === 'true';
  }

  get skeletonItemName() {
    return 'skeleton';
  }
}
