import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['iframePreview', 'imgPreview', 'img'];

  static values = {
    url: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'pdf',
    },
    imgZoomStep: {
      type: Number,
      default: 0.2,
    },
    imgScale: {
      type: Number,
      default: 1.0,
    },
    imgTranslateX: {
      type: Number,
      default: 0,
    },
    imgTranslateY: {
      type: Number,
      default: 0,
    },
  };

  connect() {
    this.panning = false;
    this.panningStartX = 0;
    this.panningEndX = 0;
  }

  imgZoomOut() {
    this.imgScaleValue = Math.max(0, this.imgScaleValue - this.imgZoomStepValue);
  }

  imgZoomIn() {
    this.imgScaleValue += this.imgZoomStepValue;
  }

  imgFit() {
    this.imgScaleValue = 1;
    this.imgTranslateXValue = 0;
    this.imgTranslateYValue = 0;
  }

  startPanning(event) {
    event.preventDefault();

    this.panning = true;

    const coords = event.touches?.[0] || event;

    this.panningStartX = coords.clientX - this.imgTranslateXValue * this.imgScaleValue;
    this.panningStartY = coords.clientY - this.imgTranslateYValue * this.imgScaleValue;

    this.imgTarget.style.cursor = 'grabbing';
  }

  stopPanning(event) {
    event.preventDefault();

    this.panning = false;
    this.imgTarget.style.cursor = 'grab';
  }

  pan(event) {
    if (!this.panning) return;

    event.preventDefault();

    const coords = event.touches?.[0] || event;
    const [imgTranslateXValue, imgTranslateYValue] = this.calculatePanTranslation(coords);

    this.imgTranslateXValue = imgTranslateXValue / this.imgScaleValue;
    this.imgTranslateYValue = imgTranslateYValue / this.imgScaleValue;
  }

  calculatePanTranslation(coords) {
    let imgTranslateXValue = coords.clientX - this.panningStartX;
    let imgTranslateYValue = coords.clientY - this.panningStartY;

    const containerRect = this.imgTarget.parentElement.getBoundingClientRect();
    const imgRect = this.imgTarget.getBoundingClientRect();

    const excessX = (imgRect.width - containerRect.width) / 2;
    const excessY = (imgRect.height - containerRect.height) / 2;

    if (excessX <= 0) {
      imgTranslateXValue = 0;
    }

    if (excessY <= 0) {
      imgTranslateYValue = 0;
    }

    return [imgTranslateXValue, imgTranslateYValue];
  }

  imgScaleValueChanged() {
    this.updateImgStyleTransform();
  }

  imgTranslateXValueChanged() {
    this.updateImgStyleTransform();
  }

  imgTranslateYValueChanged() {
    this.updateImgStyleTransform();
  }

  updateImgStyleTransform() {
    this.imgTarget.style.transform = `scale(${this.imgScaleValue}) translate(${this.imgTranslateXValue}px, ${this.imgTranslateYValue}px)`;
  }

  imgError() {
    this.typeValue = 'iframe';

    this.iframePreviewTarget.src = `${this.urlValue}#view=Fit`;
    this.imgTarget.removeAttribute('src');
  }

  typeValueChanged() {
    if (this.typeValue === 'none') {
      this.iframePreviewTarget.hidden = true;
      this.imgPreviewTarget.hidden = true;

      return;
    }

    const iframe = this.typeValue === 'iframe';
    this.iframePreviewTarget.hidden = !iframe;
    this.imgPreviewTarget.hidden = iframe;
  }

  urlValueChanged() {
    if (!this.urlValue) {
      this.typeValue = 'none';
    } else if (this.urlValue.toLowerCase().includes('.pdf')) {
      this.typeValue = 'iframe';

      this.iframePreviewTarget.src = `${this.urlValue}#view=Fit`;
      this.imgTarget.removeAttribute('src');
    } else {
      this.typeValue = 'image';

      this.iframePreviewTarget.removeAttribute('src');
      this.imgTarget.src = this.urlValue;
    }
  }
}
