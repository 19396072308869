import ApplicationController from 'modules/application_controller';
import getOffset from 'plugins/element/get_offset';
import scrollTop from 'plugins/utilities/scroll_top';
import viewportHeight from 'plugins/utilities/viewport_height';
import viewportWidth from 'plugins/utilities/viewport_width';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      modalContentTarget: undefined,
    };
  }

  connect() {
    if (this.scrollToOnInit) {
      this.checkForModal();
      this.scrollToViewport();
    }
  }

  scrollToViewport() {
    const currentScrollTop = scrollTop({ element: this.modalContentTarget });
    const topOffset = getOffset('top', this.element, this.modalContentTarget || document);
    const headerOffset = viewportWidth() >= 768 ? 68 : 8;
    const targetScrollTop = topOffset - (this.modalContentTarget ? 8 : headerOffset);

    if (targetScrollTop < currentScrollTop || targetScrollTop > currentScrollTop + viewportHeight()) {
      scrollTop({ value: targetScrollTop, element: this.modalContentTarget });
    }
  }

  close(event) {
    event.stopPropagation();

    this.element.hidden = true;
  }

  checkForModal() {
    const modals = document.querySelectorAll('[data-controller~="fuse--modal"], [data-controller~="fuse--modal-v2"]');
    for (let i = 0; i < modals.length; i++) {
      if (modals[i].contains(this.element)) {
        if (modals[i].tagName.toLowerCase() === 'dialog') {
          this.modalContentTarget = modals[i];
        } else {
          this.modalContentTarget = modals[i].firstElementChild;
        }
        return;
      }
    }
  }

  get modalContentTarget() {
    return this.props.modalContentTarget;
  }

  set modalContentTarget(value) {
    this.props.modalContentTarget = value;
  }

  get scrollToOnInit() {
    return this.element.dataset.scrollToOnInit === 'true';
  }
}
