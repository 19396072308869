import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['hiddenCentsInput', 'centsInput', 'currencySelect'];
  }

  static get values() {
    return {
      keepCentsEmpty: {
        type: Boolean,
        default: false,
      },
      keepWholeCents: {
        type: Boolean,
        default: false,
      },
      templateValue: {
        type: Number,
        default: null,
      },
    };
  }

  initialize() {
    this.centsValueObserver = new MutationObserver(this.valueChangeObserver.bind(this));
    this.skipFireChange = false;
    this.skipNextCentsInputDisabledChange = false;
    this.currencySelectInitialDisabled = false;
  }

  connect() {
    this.skipFireChange = true;
    this.skipNextCentsInputDisabledChange = false;
    this.currencySelectInitialDisabled = this.currencySelectTarget.disabled;

    if (this.templateValueValue) {
      if (this.hasHiddenCentsInputTarget) {
        this.hiddenCentsInputTarget.value = this.templateValueValue;
        this.updateCentsFromHiddenCents();
      } else {
        this.centsInputTarget.value = this.templateValueValue;
      }
    }

    this.updateHiddenCents();

    this.centsValueObserver.observe(this.centsInputTarget, {
      attributeFilter: ['value', 'disabled', 'readonly', 'required'],
    });

    if (this.hasHiddenCentsInputTarget) {
      this.centsValueObserver.observe(this.hiddenCentsInputTarget, {
        attributeFilter: ['value', 'disabled', 'readonly', 'required'],
      });
    }
  }

  disconnect() {
    this.centsValueObserver.disconnect();
  }

  valueChangeObserver(mutationList) {
    for (const { type, attributeName, target } of mutationList) {
      switch (type) {
        case 'attributes':
          switch (attributeName) {
            case 'disabled':
              if (this.skipNextCentsInputDisabledChange) {
                this.skipNextCentsInputDisabledChange = false;

                break;
              }

              if (target === this.hiddenCentsInputTarget) {
                this.skipNextCentsInputDisabledChange = true;
                this.centsInputTarget.disabled = this.hiddenCentsInputTarget.disabled;
              }

              if (!this.currencySelectInitialDisabled) {
                this.currencySelectTarget.disabled = this.centsInputTarget.disabled || this.centsInputTarget.readOnly;
              }

              break;
            case 'readonly':
              if (target === this.hiddenCentsInputTarget) {
                this.centsInputTarget.readOnly = this.hiddenCentsInputTarget.readOnly;
              }

              if (!this.currencySelectInitialDisabled) {
                this.currencySelectTarget.disabled = this.centsInputTarget.readOnly || this.centsInputTarget.disabled;
              }

              break;
            case 'required':
              if (target === this.hiddenCentsInputTarget) {
                this.centsInputTarget.required = this.hiddenCentsInputTarget.required;
              }

              break;
            case 'value':
              if (target === this.hiddenCentsInputTarget) {
                this.updateCentsFromHiddenCents();
              }

              break;
            default:
          }
          break;
        default:
      }
    }
  }

  updateHiddenCents(event = null) {
    if (!this.hasHiddenCentsInputTarget) return;

    const inputValue = this.priceFromTarget(this.centsInputTarget);
    let centsValue = '';

    if (this.centsInputTarget.value.trim() !== '' || !this.keepCentsEmptyValue) {
      centsValue = Math.round(inputValue.toFixed(2) * 100);

      if (event?.type === 'blur' && this.keepWholeCentsValue) {
        this.centsInputTarget.value = inputValue.toFixed(2);
      }
    }

    const changed = centsValue.toString() !== this.hiddenCentsInputTarget.value;

    if (changed) {
      this.hiddenCentsInputTarget.value = centsValue;

      if (!this.skipFireChange) {
        this.fireChange();
      }
    }

    this.skipFireChange = false;
  }

  updateCentsFromHiddenCents() {
    if (!this.hasHiddenCentsInputTarget) return;
    if (document.activeElement === this.centsInputTarget) return;

    const centsValue = this.priceFromTarget(this.hiddenCentsInputTarget);
    const inputValue = centsValue / 100;

    if (!this.skipSettingWholeCents && (this.keepWholeCentsValue || inputValue % 1 !== 0)) {
      this.centsInputTarget.value = inputValue.toFixed(2);
    } else {
      this.centsInputTarget.value = inputValue;
    }
  }

  fireChange() {
    this.hiddenCentsInputTarget.dispatchEvent(new Event('change', { bubbles: true }));
    this.hiddenCentsInputTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  priceFromTarget(target) {
    return Number(target.value.trim().replaceAll(',', '.').replace(/[,.]$/, '')) || 0;
  }
}
