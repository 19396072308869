import ApplicationController from 'modules/application_controller';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class extends ApplicationController {
  static targets = ['close'];

  initialize() {
    this.foundModalController = null;
    this.initialPersistent = null;
    this.initialCloseOnBgClick = null;
  }

  connect() {
    defer(this._initializeContent.bind(this));
  }

  disconnect() {
    this._restoreInitialContent();
  }

  _initializeContent() {
    const { dataset } = this.element;
    const contentPersistent = dataset.persistent === 'true';
    const contentCloseOnBgClick = dataset.closeOnBgClick === 'true';

    if (contentPersistent !== this.persistent) {
      this.initialPersistent = this.persistent;
      this.persistent = contentPersistent;
    }

    if (contentCloseOnBgClick !== this.closeOnBgClick) {
      this.initialCloseOnBgClick = this.closeOnBgClick;
      this.closeOnBgClick = contentCloseOnBgClick;
    }
  }

  _restoreInitialContent() {
    if (isVariableDefinedNotNull(this.initialPersistent)) {
      this.persistent = this.initialPersistent;
      this.initialPersistent = null;
    }

    if (isVariableDefinedNotNull(this.initialCloseOnBgClick)) {
      this.closeOnBgClick = this.initialCloseOnBgClick;
      this.initialCloseOnBgClick = null;
    }
  }

  preventClose() {
    this.persistent = true;
  }

  allowClose() {
    this.persistent = false;
  }

  acceptModal(closeModal = true, { data = undefined } = {}) {
    this.modalController.accept(closeModal, { data });
  }

  rejectModal(closeModal = true, { data = undefined } = {}) {
    this.modalController.reject(closeModal, { data });
  }

  acceptOnDone(event) {
    const { target } = event.detail;

    if (
      !target ||
      !this.acceptWhenDone ||
      (!this.element.contains(target) && !this.element.querySelector(`[type="submit"][form="${target.id}"]`))
    ) {
      return;
    }

    this.acceptModal(true);
  }

  set persistent(value) {
    this.modalController.persistent = value;
  }

  get persistent() {
    return this.modalController.persistent;
  }

  set closeOnBgClick(value) {
    this.modalController.closeOnBgClick = value;
  }

  get closeOnBgClick() {
    return this.modalController.closeOnBgClick;
  }

  get acceptWhenDone() {
    return this.element.dataset.acceptWhenDone === 'true';
  }

  get modalController() {
    if (!this.foundModalController) {
      this.foundModalController = this.findParentControllerByName(this.identifier.replace(/content[-]?/, ''), {
        hideWarning: true,
      });
    }

    return this.foundModalController;
  }
}
