import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['result'];

  initialize() {
    this.resultTimeoutId = null;
  }

  showResult() {
    clearTimeout(this.resultTimeoutId);

    this.resultTarget.hidden = false;
    this.resultTimeoutId = setTimeout(() => {
      this.resultTarget.hidden = true;
    }, 3000);
  }

  async copy(event) {
    event.preventDefault();

    try {
      if (this.value) {
        await navigator.clipboard.writeText(this.value);
      } else if (this.forId) {
        const element = this.elementToCopy;

        if (element) await this.copyFromElement(element);
      }

      this.showResult();
      this.dispatch('copied');
    } catch (error) {
      console.error('Copy failed:', error);
    }
  }

  async copyFromElement(element) {
    if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
      await navigator.clipboard.writeText(element.value);
    } else if (element instanceof HTMLAnchorElement && element.hasAttribute('href')) {
      await navigator.clipboard.writeText(element.href);
    } else {
      await navigator.clipboard.writeText(element.textContent || '');
    }
  }

  get elementToCopy() {
    const root = this.element.getRootNode();

    return root instanceof Document || root instanceof ShadowRoot ? root.getElementById(this.forId) : null;
  }

  get value() {
    return this.element.dataset.value;
  }

  get forId() {
    return this.element.dataset.forId;
  }
}
