import RelativeTimeController from '@/fuse/javascript/relative_time_controller';
import RelativeTime from 'modules/relative_time';

export default class extends RelativeTimeController {
  static values = {
    ...this.values,
    micro: {
      type: Boolean,
      default: false,
    },
  };

  microValueChanged() {
    super.updateElement();
    super.scheduleUpdateElement();
  }

  get formattedDate() {
    if (!this.parsedDatetime) return null;

    const relativeTime = new RelativeTime(this.parsedDatetime, {
      locale: this.locale,
      precise: this.precise,
      alwaysRelative: true,
      limitElapsed: false,
    });

    if (this.microValue) {
      return relativeTime.microTimeAgo();
    }

    return relativeTime.toString();
  }
}
