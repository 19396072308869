import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['drawer'];

  open() {
    this.dispatch('open', { target: this.drawerTarget, prefix: 'dialog', bubbles: false });
  }

  close() {
    this.dispatch('close', { target: this.drawerTarget, prefix: 'dialog', bubbles: false });
  }
}
