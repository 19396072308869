import dialogPolyfill from 'dialog-polyfill';
import ApplicationController from 'modules/application_controller';
import animationsComplete from 'plugins/element/animations_complete';
import removeBodyOverflowHidden from 'plugins/utilities/remove_body_overflow_hidden';
import setBodyOverflowHidden from 'plugins/utilities/set_body_overflow_hidden';

export default class extends ApplicationController {
  static values = {
    initOpen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    closeOnBgClick: {
      type: Boolean,
      default: true,
    },
    childrenAreNotContent: {
      type: Boolean,
      default: false,
    },
  };

  initialize() {
    this.acceptClose = false;
    this.observed = false;
    this.observer = new MutationObserver(this.handleObserver.bind(this));
  }

  connect() {
    this.acceptClose = false;

    if (typeof HTMLDialogElement !== 'function') {
      this.element.classList.add('tw-hidden', 'open:tw-block');
      dialogPolyfill.registerDialog(this.element);
    }

    this.startObserve();

    if (this.initOpenValue) {
      this.open();
    }
  }

  disconnect() {
    this.destroy();
  }

  destroy() {
    this.endObserve();
    this.close();
  }

  startObserve() {
    this.observer.observe(this.element, {
      attributes: true,
    });
    this.observed = true;
  }

  endObserve() {
    this.observed = false;
    this.observer.disconnect();
  }

  handleObserver(mutations) {
    mutations.forEach(async (mutation) => {
      if (mutation.type === 'attributes') {
        if (mutation.attributeName === 'open') {
          const { target } = mutation;

          if (!this.isOpen) {
            removeBodyOverflowHidden();

            return;
          }

          setBodyOverflowHidden();

          target.removeAttribute('inert');

          this.dispatch('opening');

          await animationsComplete(this.element);

          this.dispatch('opened');
        }
      }
    });
  }

  open() {
    if (this.isOpen) return;

    this.element.showModal();
  }

  async close() {
    if (!this.isOpen) return;

    if (!this.observed) {
      removeBodyOverflowHidden();
    }

    this.element.classList.add('hiding');

    await animationsComplete(this.element);

    this.element.close('dismiss');

    this.element.classList.remove('hiding');
  }

  triggerClose() {
    if (this.persistentValue || !this.closeOnBgClickValue) return;
    if (!this.acceptClose) return;

    this.close();

    this.acceptClose = false;
  }

  updateAcceptClose({ target }) {
    this.acceptClose =
      target.nodeName === 'DIALOG' || (this.childrenAreNotContentValue && [...this.element.children].includes(target));
  }

  onCancel(event) {
    event.stopPropagation();

    if (this.persistentValue) {
      event.preventDefault();
      return;
    }

    this.dispatch('cancel');
  }

  async onClose(event) {
    event.stopPropagation();

    this.element.setAttribute('inert', '');

    this.dispatch('closing');

    await animationsComplete(this.element);

    this.dispatch('closed');
  }

  get isOpen() {
    return this.element.hasAttribute('open');
  }
}
