import { AsYouType, getExampleNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['phone', 'number', 'countryCode'];
  }

  static get outlets() {
    return ['fuse--choices-phone-country-code'];
  }

  connect() {
    this.props = {
      defaultPlaceholder: '',
    };

    if (this.numberTarget.placeholder) {
      this.defaultPlaceholder = this.numberTarget.placeholder;
    }

    this.updateAll();
  }

  updateAll() {
    const asYouType = new AsYouType({ defaultCallingCode: this.countryCodeTarget.value || undefined });
    asYouType.input(this.numberTarget.value);

    const number = asYouType.getNumber();

    if (!number) {
      this.phoneTarget.value = `${this.countryCodeTarget.value}${this.numberTarget.value}`;
      this.fireChange();

      this.setValidity(null);
    } else {
      this.phoneTarget.value = number.number.replace('+', '00');
      this.fireChange();

      if (number.country && number.countryCallingCode !== this.countryCodeTarget.value) {
        this.fuseChoicesPhoneCountryCodeOutlet.setCountryCode(number.countryCallingCode);
      }

      this.setValidity(number);
    }

    this.updatePlaceholder();

    if (number) {
      this.numberTarget.value = number.formatNational();
    }
  }

  updatePlaceholder() {
    if (!this.countryCodeProperties || Object.keys(this.countryCodeProperties).length === 0) {
      this.numberTarget.placeholder = this.defaultPlaceholder;
      return;
    }

    const number = getExampleNumber(this.countryCodeProperties.country_char_code, examples);
    this.numberTarget.placeholder = number.formatNational();
  }

  setValidity(number = null) {
    if (!number) {
      this.numberTarget.dataset.invalidNumber = !!(this.countryCodeTarget.value || this.numberTarget.value);
      this.numberTarget.dataset.numberTooShort = false;
      this.numberTarget.dataset.numberTooLong = false;

      return;
    }

    const numberLength = validatePhoneNumberLength(number.number, number.country);

    if (numberLength === 'TOO_SHORT') {
      this.numberTarget.dataset.invalidNumber = false;
      this.numberTarget.dataset.numberTooLong = false;
      this.numberTarget.dataset.numberTooShort = true;

      return;
    }

    if (numberLength === 'TOO_LONG') {
      this.numberTarget.dataset.invalidNumber = false;
      this.numberTarget.dataset.numberTooShort = false;
      this.numberTarget.dataset.numberTooLong = true;

      return;
    }

    this.numberTarget.dataset.numberTooShort = false;
    this.numberTarget.dataset.numberTooLong = false;
    this.numberTarget.dataset.invalidNumber = !number.isValid();
  }

  fireChange() {
    this.phoneTarget.dispatchEvent(new Event('change', { bubbles: true }));
    this.phoneTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  get countryCodeProperties() {
    return this.fuseChoicesPhoneCountryCodeOutlet.value?.customProperties;
  }

  get defaultPlaceholder() {
    return this.props.defaultPlaceholder;
  }

  set defaultPlaceholder(value) {
    this.props.defaultPlaceholder = value;
  }
}
