import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    if (!this.url) {
      return;
    }

    window.open(this.url, '_blank');
    this.element.removeAttribute('data-url');
  }

  get url() {
    return this.element.dataset.url;
  }
}
